import {
  combineReducers,
  configureStore,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch } from 'react-redux';

import { companyApi } from '../services/acompanyApi';
import { importApi } from '../services/aimportApi';
import { usersApi } from '../services/ausersApi';
import { authSlice } from '../services/authSlice';

import { mitemApi } from '../services/mitemApi';
import { mgodownApi } from '../services/mgodownApi';
import { mvehicleApi } from '../services/mvehicleApi';
import { memployeeApi } from '../services/memployeeApi';

import { evaninvoiceApi } from '../services/evaninvoiceApi';
import { evanloadApi } from '../services/evanloadApi';
import { einvoiceApi } from '../services/einvoiceApi';
import { esalreturnApi } from '../services/esalreturnApi';
import { epurchaseApi } from '../services/epurchaseApi';

import { etransferApi } from '../services/etransferApi';
import { edispatchApi } from '../services/edispatchApi';
import { estockauditApi } from '../services/estockauditApi';
import { estockadjApi } from '../services/estockadjApi';
import { estock1auditApi } from '../services/estock1auditApi';
import { ereturnApi } from '../services/ereturnApi';

import { rstockApi } from '../services/rstockApi';
import { rstock1Api } from '../services/rstock1Api';
import { rvehicleApi } from '../services/rvehicleApi';
import { rdeliverboyApi } from '../services/rdeliverboyApi';
import { rstockpredictionApi } from '../services/rstockpredictionApi';

import { dashboardApi } from '../services/dashboardApi';

import notification from '../../components/Notification';
import { logicSlice } from '../services/agen';

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      switch (action.payload.originalStatus) {
        case 500: {
          notification.error({
            title: 'Server Error',
            message: action.payload.data
          });
          break;
        }
        case 400: {
          notification.error({
            title: 'Error',
            message: action.payload.data
          });
          break;
        }
        case 401: {
          notification.error({
            title: 'Error',
            message: action.payload.data
          });
          //Logout();
          break;
        }
        case 300: {
          notification.error({
            title: 'Error',
            message: action.payload.data
          });
          break;
        }
        case 200: {
          notification.success({
            title: 'Sucess',
            message: action.payload.data
          });
          break;
        }
        default: {
          notification.error({
            title: 'Error',
            message: action.payload.error
          });
          break;
        }
      }
      return undefined;
    } else {
      return next(action);
    }
  };

const rootReducer = combineReducers({
  [usersApi.reducerPath]: usersApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  [importApi.reducerPath]: importApi.reducer,
  [mitemApi.reducerPath]: mitemApi.reducer,
  [mvehicleApi.reducerPath]: mvehicleApi.reducer,
  [memployeeApi.reducerPath]: memployeeApi.reducer,
  [mgodownApi.reducerPath]: mgodownApi.reducer,
  [evaninvoiceApi.reducerPath]: evaninvoiceApi.reducer,
  [einvoiceApi.reducerPath]: einvoiceApi.reducer,
  [evanloadApi.reducerPath]: evanloadApi.reducer,
  [esalreturnApi.reducerPath]: esalreturnApi.reducer,
  [epurchaseApi.reducerPath]: epurchaseApi.reducer,
  [etransferApi.reducerPath]: etransferApi.reducer,
  [edispatchApi.reducerPath]: edispatchApi.reducer,
  [estockauditApi.reducerPath]: estockauditApi.reducer,
  [estockadjApi.reducerPath]: estockadjApi.reducer,
  [estock1auditApi.reducerPath]: estock1auditApi.reducer,
  [ereturnApi.reducerPath]: ereturnApi.reducer,
  [rstockApi.reducerPath]: rstockApi.reducer,
  [rstock1Api.reducerPath]: rstock1Api.reducer,
  [rvehicleApi.reducerPath]: rvehicleApi.reducer,
  [rdeliverboyApi.reducerPath]: rdeliverboyApi.reducer,
  [rstockpredictionApi.reducerPath]: rstockpredictionApi.reducer,
  [dashboardApi.reducerPath]: dashboardApi.reducer,

  gdata: authSlice.reducer,
  glogic: logicSlice.reducer
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddiware) =>
    getDefaultMiddiware().concat(
      rtkQueryErrorLogger,
      usersApi.middleware,
      companyApi.middleware,
      importApi.middleware,
      mitemApi.middleware,
      mvehicleApi.middleware,
      memployeeApi.middleware,
      mgodownApi.middleware,
      evaninvoiceApi.middleware,
      einvoiceApi.middleware,
      evanloadApi.middleware,
      esalreturnApi.middleware,
      epurchaseApi.middleware,
      etransferApi.middleware,
      edispatchApi.middleware,
      estockauditApi.middleware,
      estockadjApi.middleware,
      estock1auditApi.middleware,
      ereturnApi.middleware,
      rstockApi.middleware,
      rstock1Api.middleware,
      rvehicleApi.middleware,
      rdeliverboyApi.middleware,
      rstockpredictionApi.middleware,
      dashboardApi.middleware
    ),
  devTools: true
});

export default store;
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
