import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Auth = Loader(lazy(() => import('src/screens/auth')));

const AdminCompany = Loader(
  lazy(() => import('src/screens/pages/Admin/Company'))
);

const AdminUsers = Loader(lazy(() => import('src/screens/pages/Admin/Users')));
// Dashboards

const Crypto = Loader(lazy(() => import('src/screens/dashboards/Crypto')));
const AddProduct = Loader(
  lazy(() => import('src/screens/dashboards/Crypto/AddProd'))
);
const Godown = Loader(lazy(() => import('src/screens/pages/Masters/Godown')));
const Vehicle = Loader(lazy(() => import('src/screens/pages/Masters/Vehicle')));
const Employee = Loader(
  lazy(() => import('src/screens/pages/Masters/Employee'))
);

const Item = Loader(lazy(() => import('src/screens/pages/Import/Item')));
const ItemList = Loader(
  lazy(() => import('src/screens/pages/Import/ItemList'))
);
const VanInvoice = Loader(
  lazy(() => import('src/screens/pages/Import/VanInvoice'))
);
const VanLoad = Loader(lazy(() => import('src/screens/pages/Import/VanLoad')));
const Invoice = Loader(lazy(() => import('src/screens/pages/Import/Invoice')));
const InvoiceFree = Loader(
  lazy(() => import('src/screens/pages/Import/InvoiceFree'))
);
const SalesReturn = Loader(
  lazy(() => import('src/screens/pages/Import/SalesReturn'))
);
const Purchase = Loader(
  lazy(() => import('src/screens/pages/Import/Purchase'))
);

const AlterLoadChart = Loader(
  lazy(() => import('src/screens/pages/Entry/AlterLoadChart'))
);
const Transfer = Loader(lazy(() => import('src/screens/pages/Entry/Transfer')));
const TransferUC = Loader(
  lazy(() => import('src/screens/pages/Entry/TransferUC'))
);
const Dispatch = Loader(lazy(() => import('src/screens/pages/Entry/Dispatch')));
const StockAudit = Loader(
  lazy(() => import('src/screens/pages/Entry/StockAudit'))
);
const Stock1Audit = Loader(
  lazy(() => import('src/screens/pages/Entry/Stock1Audit'))
);
const StockReturn = Loader(
  lazy(() => import('src/screens/pages/Entry/StockReturn'))
);

const Stock = Loader(lazy(() => import('src/screens/pages/Reports/Stock')));
const StockIndex = Loader(
  lazy(() => import('src/screens/pages/Reports/StockIndex'))
);
const StockAuditRep = Loader(
  lazy(() => import('src/screens/pages/Reports/StockAudit'))
);
const Stock1 = Loader(lazy(() => import('src/screens/pages/Reports/Stock1')));
const ReprintTransfer = Loader(
  lazy(() => import('src/screens/pages/Reports/ReprintTransfer'))
);
const ReprintDispatch = Loader(
  lazy(() => import('src/screens/pages/Reports/ReprintDispatch'))
);
const VehicleWise = Loader(
  lazy(() => import('src/screens/pages/Reports/VehicleWise'))
);
const DeliveryBoywise = Loader(
  lazy(() => import('src/screens/pages/Reports/DeliveryBoywise'))
);
const DatewiseTransfer = Loader(
  lazy(() => import('src/screens/pages/Reports/DatewiseTransfer'))
);
const DatewiseDispatch = Loader(
  lazy(() => import('src/screens/pages/Reports/DatewiseDispatch'))
);
const PendingTransfer = Loader(
  lazy(() => import('src/screens/pages/Reports/PendingTransfer'))
);
const PendingDispatch = Loader(
  lazy(() => import('src/screens/pages/Reports/PendingDispatch'))
);
const StockPrediction = Loader(
  lazy(() => import('src/screens/pages/Reports/StockPrediction'))
);

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Crypto />
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      }
    ]
  },
  {
    path: 'import',
    element: <SidebarLayout />,
    children: [
      {
        path: 'importitem',
        element: <Item />
      },
      {
        path: 'importitemlist',
        element: <ItemList />
      },
      {
        path: 'vaninvoice',
        element: <VanInvoice />
      },
      {
        path: 'vanload',
        element: <VanLoad />
      },
      {
        path: 'invoice',
        element: <Invoice />
      },
      {
        path: 'invoice1',
        element: <InvoiceFree />
      },
      {
        path: 'salesreturn',
        element: <SalesReturn />
      },
      {
        path: 'purchase',
        element: <Purchase />
      }
    ]
  },
  {
    path: 'masters',
    element: <SidebarLayout />,
    children: [
      {
        path: 'godown',
        element: <Godown />
      },
      {
        path: 'vehicle',
        element: <Vehicle />
      },
      {
        path: 'employee',
        element: <Employee />
      },
      {
        path: 'addprod/:code/:name/:uom/:mrp',
        element: <AddProduct />
      }
    ]
  },
  {
    path: 'entry',
    element: <SidebarLayout />,
    children: [
      {
        path: 'alterlc',
        element: <AlterLoadChart />
      },
      {
        path: 'transfer',
        element: <Transfer />
      },
      {
        path: 'transferuc',
        element: <TransferUC />
      },
      {
        path: 'dispatch',
        element: <Dispatch />
      },
      {
        path: 'stockaudit',
        element: <StockAudit />
      },
      {
        path: 'stock1audit',
        element: <Stock1Audit />
      },
      {
        path: 'stockreturn',
        element: <StockReturn />
      }
    ]
  },
  {
    path: 'reports',
    element: <SidebarLayout />,
    children: [
      {
        path: 'stock',
        element: <Stock />
      },
      {
        path: 'stockindex',
        element: <StockIndex />
      },
      {
        path: 'stockaudit',
        element: <StockAuditRep />
      },
      {
        path: 'stock1',
        element: <Stock1 />
      },
      {
        path: 'reprinttransfer',
        element: <ReprintTransfer />
      },
      {
        path: 'reprintdispatch',
        element: <ReprintDispatch />
      },
      {
        path: 'vehicle',
        element: <VehicleWise />
      },
      {
        path: 'deliboy',
        element: <DeliveryBoywise />
      },
      {
        path: 'transfer',
        element: <DatewiseTransfer />
      },
      {
        path: 'dispatch',
        element: <DatewiseDispatch />
      },
      {
        path: 'pendtransfer',
        element: <PendingTransfer />
      },
      {
        path: 'penddispatch',
        element: <PendingDispatch />
      },
      {
        path: 'stockprediction',
        element: <StockPrediction />
      }
    ]
  },
  {
    path: 'admin',
    element: <SidebarLayout />,
    children: [
      {
        path: 'company',
        element: <AdminCompany />
      },
      {
        path: 'users',
        element: <AdminUsers />
      }
    ]
  },
  {
    path: '*',
    element: <Auth />
  }
];

const authroutes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Auth />
      },
      {
        path: 'company',
        element: <AdminCompany />
      }
    ]
  },
  {
    path: '*',
    element: <Auth />
  }
];

export default routes;
export { authroutes };
