import { useContext, useState } from 'react';

import {
  alpha,
  Box,
  Button,
  Collapse,
  List,
  ListItem,
  ListSubheader,
  styled
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import BrightnessLowTwoToneIcon from '@mui/icons-material/BrightnessLowTwoTone';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import MmsTwoToneIcon from '@mui/icons-material/MmsTwoTone';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import { RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { retrieveSetting } from 'src/Common/common';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const gdata = useSelector((state: RootState) => state.gdata);
  const { closeSidebar } = useContext(SidebarContext);

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);

  const handleClick = () => {
    setOpen(!open);

    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
  };

  const handleClick1 = () => {
    setOpen1(!open1);

    setOpen(false);
    setOpen2(false);
    setOpen3(false);
    setOpen4(false);
  };

  const handleClick2 = () => {
    setOpen2(!open2);

    setOpen(false);
    setOpen1(false);
    setOpen3(false);
    setOpen4(false);
  };

  const handleClick3 = () => {
    setOpen3(!open3);

    setOpen(false);
    setOpen1(false);
    setOpen2(false);
    setOpen4(false);
  };

  const handleClick4 = () => {
    setOpen4(!open4);

    setOpen(false);
    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
  };

  return (
    <>
      <MenuWrapper>
        <List component="div">
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/"
                  startIcon={<DesignServicesTwoToneIcon />}
                >
                  Dashboards
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List
          component="div"
          subheader={
            <Button
              onClick={handleClick}
              endIcon={open ? <ExpandLess /> : <ExpandMore />}
            >
              <ListSubheader component="div" disableSticky>
                Import
              </ListSubheader>
            </Button>
          }
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <SubMenuWrapper>
              <List component="div">
                {gdata.username === 'Admin' ? (
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/import/importitem"
                      startIcon={<BrightnessLowTwoToneIcon />}
                    >
                      Item Master
                    </Button>
                  </ListItem>
                ) : (
                  <></>
                )}
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/import/vaninvoice"
                    startIcon={<MmsTwoToneIcon />}
                  >
                    Van Invoice
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/import/vanload"
                    startIcon={<MmsTwoToneIcon />}
                  >
                    Van Loading
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/import/invoice"
                    startIcon={<MmsTwoToneIcon />}
                  >
                    Invoice
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/import/invoice1"
                    startIcon={<MmsTwoToneIcon />}
                  >
                    Invoice Free
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/import/salesreturn"
                    startIcon={<MmsTwoToneIcon />}
                  >
                    Sales Return
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/import/purchase"
                    startIcon={<MmsTwoToneIcon />}
                  >
                    Purchase
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </Collapse>
        </List>
        {gdata.username === 'Admin' ? (
          <List
            component="div"
            subheader={
              <Button
                onClick={handleClick1}
                endIcon={open1 ? <ExpandLess /> : <ExpandMore />}
              >
                <ListSubheader component="div" disableSticky>
                  Master
                </ListSubheader>
              </Button>
            }
          >
            <Collapse in={open1} timeout="auto" unmountOnExit>
              <SubMenuWrapper>
                <List component="div">
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/masters/godown"
                      startIcon={<TableChartTwoToneIcon />}
                    >
                      Godown Master
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/masters/vehicle"
                      startIcon={<TableChartTwoToneIcon />}
                    >
                      Vehicle Master
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/masters/employee"
                      startIcon={<TableChartTwoToneIcon />}
                    >
                      Employee Master
                    </Button>
                  </ListItem>
                </List>
              </SubMenuWrapper>
            </Collapse>
          </List>
        ) : (
          <></>
        )}
        {retrieveSetting('newprods') ? null : (
          <List
            component="div"
            subheader={
              <Button
                onClick={handleClick2}
                endIcon={open2 ? <ExpandLess /> : <ExpandMore />}
              >
                <ListSubheader component="div" disableSticky>
                  Entry
                </ListSubheader>
              </Button>
            }
          >
            <Collapse in={open2} timeout="auto" unmountOnExit>
              <SubMenuWrapper>
                <List component="div">
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/entry/alterlc"
                      startIcon={<AccountCircleTwoToneIcon />}
                    >
                      Alter Load Chart
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/entry/transfer"
                      startIcon={<AccountCircleTwoToneIcon />}
                    >
                      Transfer
                    </Button>
                  </ListItem>
                  {/* <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/entry/transferuc"
                      startIcon={<AccountCircleTwoToneIcon />}
                    >
                      Transfer UnConditional
                    </Button>
                  </ListItem> */}
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/entry/dispatch"
                      startIcon={<AccountCircleTwoToneIcon />}
                    >
                      Dispatch
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/entry/stockaudit"
                      startIcon={<AccountCircleTwoToneIcon />}
                    >
                      Stock Audit
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/entry/stock1audit"
                      startIcon={<AccountCircleTwoToneIcon />}
                    >
                      Stock Audit - Godown
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/entry/stockreturn"
                      startIcon={<AccountCircleTwoToneIcon />}
                    >
                      Stock Return
                    </Button>
                  </ListItem>
                </List>
              </SubMenuWrapper>
            </Collapse>
          </List>
        )}
        <List
          component="div"
          subheader={
            <Button
              onClick={handleClick4}
              endIcon={open4 ? <ExpandLess /> : <ExpandMore />}
            >
              <ListSubheader component="div" disableSticky>
                Reports
              </ListSubheader>
            </Button>
          }
        >
          <Collapse in={open4} timeout="auto" unmountOnExit>
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/reports/stock"
                    startIcon={<AccountCircleTwoToneIcon />}
                  >
                    Stock Ledger
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/reports/stockindex"
                    startIcon={<AccountCircleTwoToneIcon />}
                  >
                    Stock Index
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/reports/stockaudit"
                    startIcon={<AccountCircleTwoToneIcon />}
                  >
                    Stock Audit
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/reports/stock1"
                    startIcon={<AccountCircleTwoToneIcon />}
                  >
                    Stock Ledger - Godown
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/reports/reprinttransfer"
                    startIcon={<AccountCircleTwoToneIcon />}
                  >
                    Reprint Transfer
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/reports/reprintdispatch"
                    startIcon={<AccountCircleTwoToneIcon />}
                  >
                    Reprint Dispatch
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/reports/vehicle"
                    startIcon={<AccountCircleTwoToneIcon />}
                  >
                    Vehicle-wise
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/reports/deliboy"
                    startIcon={<AccountCircleTwoToneIcon />}
                  >
                    Deliveryboy-wise
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/reports/transfer"
                    startIcon={<AccountCircleTwoToneIcon />}
                  >
                    Date-wise Transfer
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/reports/dispatch"
                    startIcon={<AccountCircleTwoToneIcon />}
                  >
                    Date-wise Dispatch
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/reports/pendtransfer"
                    startIcon={<AccountCircleTwoToneIcon />}
                  >
                    Pending Transfer
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/reports/penddispatch"
                    startIcon={<AccountCircleTwoToneIcon />}
                  >
                    Pending Dispatch
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/reports/stockprediction"
                    startIcon={<AccountCircleTwoToneIcon />}
                  >
                    Stock Prediction
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </Collapse>
        </List>
        {gdata.username === 'Admin' ? (
          <List
            component="div"
            subheader={
              <Button
                onClick={handleClick3}
                endIcon={open3 ? <ExpandLess /> : <ExpandMore />}
              >
                <ListSubheader component="div" disableSticky>
                  Admin
                </ListSubheader>
              </Button>
            }
          >
            <Collapse in={open3} timeout="auto" unmountOnExit>
              <SubMenuWrapper>
                <List component="div">
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/admin/company"
                      startIcon={<AccountCircleTwoToneIcon />}
                    >
                      Company
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      disableRipple
                      component={RouterLink}
                      onClick={closeSidebar}
                      to="/admin/users"
                      startIcon={<AccountCircleTwoToneIcon />}
                    >
                      Users
                    </Button>
                  </ListItem>
                </List>
              </SubMenuWrapper>
            </Collapse>
          </List>
        ) : (
          <></>
        )}
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
