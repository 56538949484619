import ReactLogo from './logo/logo.png';
import LoginImg from './logo/logo.png';

function BTLogo() {
  return <img src={ReactLogo} alt="BlueTech" />;
}

export function BTLoginImg() {
  return <img src={LoginImg} alt="BlueTech" />;
}

export default BTLogo;
